.MuiPhoneNumber-flagButton{
    height: 22px!important;
    min-width: 22px!important;
}
.mobile__country--code input ::after{
    border-bottom: 0px!important;
}

.customMarkerBlue{
    background: rgb(0, 143, 251) !important;
    color: rgb(0, 143, 251);
    height: 12px;
    width: 12px;
    left: 0px;
    top: 0px;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-radius: 2px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: 3px;
    margin-bottom: 10px;
    border-style: solid;
  
  }
  .customMarkerGreen{
    background: rgb(0, 227, 150) !important;
      color: rgb(0, 227, 150);
      height: 12px;
      width: 12px;
      left: 0px;
      top: 0px;
      border-width: 0px;
      border-color: rgb(255, 255, 255);
      border-radius: 2px;
      position: relative;
      display: inline-block;
      cursor: pointer;
      margin-right: 3px;
      margin-bottom: 10px;
      border-style: solid;
  }
  
@keyframes g {
  0% { background-color: rgb(0, 227, 150) }
  50% { background-color: #607e9e; }
  100% { background-color: rgb(0, 227, 150) }
}

@keyframes r {
  from { transform: rotate(0deg); }
  from { transform: rotate(180deg); }
}

.chart_div{
  display: 'block'!important;
  margin: '0 auto'!important;
}
.chart_div table{
  margin: 0 auto!important;
}
.alertFaultTab .Mui-selected{
 color: #1D1D26;
}
.alertFaultTab .MuiTabs-indicator{
  visibility: hidden;
  /* background-color: #FC4C4C; */
}

.pulse-animation{
  margin: 0 auto;
  animation-name: stretch;
  animation-duration: 300ms;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
.pulse-animation path{
  fill: #FC4C4C;
}
.alert-avatar path{
  fill: #FC4C4C;
}

@keyframes stretch {
  0% {
      transform: scale(.5);
  }
  100% {
      transform: scale(1.0);
  }
}

.stroke-gray-200 {
 stroke: #e5e7eb;
}
.stroke-green-400{
  stroke: #4ade80;
}
.stroke-gray-300{
  stroke: #d1d5db;
}
.fill-gray-700 {
  fill: #374151;
}
.fill-white {
  fill: #fff;
}
.fill-gray-300 {
  fill: #d1d5db;
}
.stroke-yellow-300{
  stroke: yellow;
}
.stroke-red-400{
  stroke: red;
}
.stroke-green-300{
  stroke: #4ade80;
}
.stroke-transparent{
  stroke: transparent;
}


.tripCollapsedStepper .MuiStepConnector-line{
  min-height: 0px!important;
}


.mapContainer {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: static;
  color: #fff;
  /* margin-top: 20px; */
}
.mapContainer > div {
  margin: 20px 20px;
}
.btnCont {
  display: flex;
  justify-content: center;
}